@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

/* html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto Condensed", sans-serif;
} */

#home:before {
  height: 0rem;
  content: "";
  display: block;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

#google_translate_element img{
  display: inline;
}

.google_translate_element img{
  display: inline;
}

nav.rdt_Pagination {
  justify-content: flex-start;
}

